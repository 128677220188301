<template>
  <div class="page page-charge-card">
    <b-col class="py-3">
      <Breadcrumb :items="[{label: $t('Charge card')}]"/>      
      <LoadingPanel v-if="loading"/>
      <div v-else-if="cardTypes">
        <div class="card-types mb-3">
          <b-button v-for="(type, index) in cardTypes" :key="index" :variant="type.id === cardType.id ? 'outline-primary' : 'light'" @click="selectCardType(type)" :disabled="submitting">
            <img :src="type.imageUrl.getUrl()" />
            <div class="type-name">{{type.name}}</div>
          </b-button>
        </div>
        <div class="card-type-content mb-3" v-if="cardType.htmlContent && cardType.htmlContent.length" v-html="cardType.htmlContent"></div>
        <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idCard" :options="cards" :disabled="submitting" value-field="id" text-field="name"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.money" :options="moneys" :disabled="submitting || formData.idCard < 1" value-field="id" text-field="name"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <div v-if="cardType.isCard">
            <b-form-group>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-input v-model="formData.serial" :disabled="submitting" :placeholder="$t('Enter a serial card')"/>
                <div class="msg-validate-error">{{ errors[0] }}</div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-input v-model="formData.pin" :disabled="submitting" :placeholder="$t('Enter a PIN card')"/>
                <div class="msg-validate-error">{{ errors[0] }}</div>
              </ValidationProvider>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-input v-model="formData.secureCode" :disabled="submitting" :placeholder="$t('Enter a secure code')"/>
                <div class="msg-validate-error">{{ errors[0] }}</div>
              </ValidationProvider>
            </b-form-group>
          </div>
          <b-form-group>
            <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="submitting">
              {{ $t("Charge now") }}
            </b-button>
          </b-form-group>
        </ValidationObserver>
      </div>
      <ErrorPanel :message="errorMessage" @retry="reloadData()" v-else/>
    </b-col>
  </div>
</template>

<script>
import chargeCardService from "@/services/chargeCardService";
import dialogHelper from "@/helpers/dialogHelper";
import config from "@/config";

export default {
  name: "Index",
  data() {
    return {
      loading: true,
      submitting: false,
      errorMessage: "",
      cardTypes: null,
      cardType: null,
      cards: [],
      moneys: [{
        id: 0,
        name: this.$t("Select a card value")
      }],
      formData: {
        idType: 0,
        idCard: 0,
        pin: "",
        serial: "",
        money: 0,
        secureCode: ""
      }
    }
  },
  async created() {
    let cardTypes = this.$store.getters.chargeCardTypes || await this.loadData();
    if (cardTypes)
    {
      cardTypes = await this.loadData();
      if (cardTypes)
        await this.$store.dispatch("setChargeCardTypes", cardTypes);
    }

    this.setCardTypes(cardTypes)
  },
  methods: {
    setCardTypes(cardTypes) {
      this.loading = false;
      this.cardTypes = cardTypes;
      if (this.cardTypes && this.cardTypes.length > 0)
      {
        this.cardType = this.cardTypes[0];
        this.formData.idType = this.cardType.id;
        this.formData.serial = "";
        this.formData.pin = "";
        this.formData.secureCode = "";
      }
    },
    async reloadData() {
      this.loading = true;
      const cardTypes = await this.loadData();
      if (cardTypes)
        await this.$store.dispatch("setChargeCardTypes", cardTypes);
      
      this.setCardTypes(cardTypes);
    },
    async loadData() {
      this.loading = true;
      const response = await chargeCardService.getCards();
      if (!response) {
        this.errorMessage = this.$t("Connect to server failed. Please check your internet connection");
        return null;
      }
      
      if (response.error) {
        this.errorMessage = this.message;
        return null;
      }

      return response.data;
    },
    selectCardType(type) {
      this.cardType = type;
      this.formData.idType = type.id;
      this.formData.serial = "";
      this.formData.pin = "";
      this.formData.secureCode = "";
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.submitting = true;
      await this.$recaptchaLoaded();
      const response = await chargeCardService.charge(this.formData, await this.$recaptcha('chargeCard'))
      this.submitting = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      this.formData.money = 0;
      this.formData.serial = "";
      this.formData.pin = "";
      this.formData.secureCode = "";
    }
  },
  watch: {
    cardType(cardType) {
      let cards = [{
        id: 0,
        name: this.$t(cardType && cardType.isCard ? "Select a card" : "Select a card type")
      }];
      
      if (cardType)
        cards = [...cards, ...cardType.cards.map(x => ({
          id: x.id,
          name: x.name + (x.inPromotion ? (` (+${(x.promoRate*100).$toString()}%)`) : "")
        }))];
      
      this.cards = cards;
      this.formData.idCard = 0;
    },
    "formData.idCard": function (idCard) {
     let moneys = [{
       id: 0,
       name: this.$t("Select a card value")
     }] ;
     
     if (idCard > 0) {
       const card = this.cardType.cards.find(x => x.id == idCard);
       if (card)
        moneys = [...moneys, ...card.moneys.map(x => ({
          id: x.from,
          name: x.from.$toString() + " " + config.moneyName + " = " + x.to.$toString() + " " + config.goldName
        }))];
     }
     
     this.moneys = moneys;
     this.formData.money = 0;
    }
  }
}
</script>

<style scoped lang="scss">
.card-types { 
  margin: 0 -5px;
  display: flex;
  
  .btn {
    margin: 0 5px;
    flex: 1;
    border-radius: 10px;
    
    img {
      max-width: 100%;
      height: 80px;
      object-fit: contain;
      margin-bottom: 3px;
    }
    
    .type-name {
      font-size: 15px;
    }
  }
}
</style>