import httpHelper from "@/helpers/httpHelper";

const chargeCardService = {
    async getCards() {
        return await httpHelper.get("/WebApi/ChargeCard/GetCards");
    },
    async charge(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/ChargeCard/Charge", data, {
            headers: {
                reCaptchaToken
            }
        });
    }
};

export default chargeCardService;